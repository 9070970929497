<template>
  <v-form ref="form" class="mt-4" v-model="valid" @submit.prevent="send">
    <v-alert v-if="success" type="success"> Your message has been sent </v-alert>
    <input type="submit" hidden />
    <div>
      <v-radio-group v-model="formData.type">
        <div class="d-flex flex-wrap">
          <span class="mr-2 text-h6 label--text" style="margin-top: -5px"> I have a </span>
          <v-radio
            v-for="(type, index) in CONTACT_TYPES"
            :key="index"
            :label="type.text"
            :value="type.value"
            class="mr-5"
          ></v-radio>
        </div>
      </v-radio-group>
    </div>
    <v-textarea
      :rules="rules.requiredRule"
      outlined
      rows="5"
      auto-grow
      v-model="formData.message"
      label="Please explain..."
    ></v-textarea>

    <v-radio-group :rules="rules.questionRule" class="reply" v-model="formData.reply">
      <div class="d-flex">
        <v-radio class="mt-2" label="No reply needed" :value="false"></v-radio>
        <v-radio class="ml-5" label="Please reply" :value="true"></v-radio>
      </div>
    </v-radio-group>
    <div>
      <v-btn
        :loading="loading"
        @click="send"
        large
        width="150"
        class="text-capitalize"
        color="primary"
        >Submit <v-icon>mdi-send</v-icon></v-btn
      >
      <v-btn @click="reset()" large color="secondary" text>Cancel </v-btn>
    </div>
    <!-- <p class="my-4 title ml-9">OR</p>
    <div>
      <v-btn
        width="150"
        class="text-capitalize"
        href="mailto:peepstruro@gmail.com"
        target="_blank"
        large
        dark
        color="cyan"
        >Email Peeps <v-icon>mdi-email</v-icon></v-btn
      >
    </div> -->
  </v-form>
</template>

<script>
import rules from '@/mixins/rules';
import contact from '@/mixins/contact';
import selection from '../mixins/selection';
import { mapGetters } from 'vuex';
import config from '../config';
import { CMS } from '../services/cms';
import { fullName } from '../utils';
const api = config.api();
export default {
  mixins: [rules, contact, selection],
  data: () => ({
    formData: {},
    defaultFormData: {
      reply: false,
      type: '',
      message: ''
    },
    valid: false,
    success: false,
    loading: false
  }),
  computed: {
    ...mapGetters({
      CONTACT_TYPES: 'selection/CONTACT_TYPES'
    })
  },
  beforeMount() {
    this.setDefaultData();
  },
  mounted() {
    this.checkType();
  },
  methods: {
    setDefaultData() {
      this.formData = this.copyObject(this.defaultFormData);
    },
    async emailMessage() {
      let html = this.formData.message;

      const user = this.$store.state.user;

      const name = fullName(user?.profile);
      if (name) {
        html += `<br/><br/>${name}<br/>${user?.email}`;
      }

      console.log(html);

      const cms = new CMS();

      const contactUsEmamilQ = await cms.get({
        key: config.keys.contactUsEmails
      });

      if (contactUsEmamilQ.docs.length) {
        const contactUsEmailsID = contactUsEmamilQ.docs[0].id;

        let type = this.CONTACT_TYPES.filter(t => t.value === this.formData.type);

        type = type[0] ? type[0].text : type;

        const data = {
          html,
          subject: `${type} from PeepsPortal ${config.isProduction() ? '' : '(dev)'}`,
          to: {
            document: contactUsEmailsID,
            collection: config.firestore.cms
          },
          from: `${config.appName}<${config.email}>`
        };
        await this.$axios.post(api.email, data);
      }
    },
    async send() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;

        try {
          await this.sendMessage(this.formData);
          await this.emailMessage();
        } catch (err) {
          console.log(err);
        }

        this.success = true;
        this.loading = false;
        this.reset();
        setTimeout(() => {
          this.success = false;
        }, 5000);
      }
    },
    reset() {
      this.setDefaultData();
      this.$refs.form.resetValidation();
    },
    checkType() {
      let type = this.$route.query.type;
      type = this.getContactType(type);
      if (!type) {
        return;
      }
      this.defaultFormData.type = type.value;
      if (type.value == 'helpRequest') {
        this.defaultFormData.reply = true;
      }
      this.setDefaultData();
    }
  }
};
</script>

<style scoped>
.reply {
  margin-top: -15px !important;
}
</style>
